
.EmployeeList-tabsBox .EmployeeList-tabs .EmployeeList-left-tabs {
    width: auto;
    /* border-bottom: 1px solid lightgray; */
    display: flex;
    flex-direction: row;
    overflow: auto;
    scrollbar-width:none;
    scrollbar-color: #888 #f1f1f1;
}



.EmployeeList-left-tabs .base-TabsList-root {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.EmployeeList-tab2 {
    padding: 10px 20px;
    width: auto ;
    background-color: #ffffff;
    /* border: 1px solid #7367f0; */
    border: none;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    color: rgb(110, 107, 107);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}



.EmployeeList-tab2:hover {
    color: #7367f0;
}

.EmployeeList-tab2 .manageicon {
    margin-right: 5px;
}
